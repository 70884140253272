import { DraggableModal, notification, Typography } from '@ui';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { SQLLabObjectSelectForm, ISQLLabObjectSelectFormProps } from '@modules/dnaObject/SQLLab/components';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { ISQLLabObject } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { prepareSQLLabObjectForImport } from '@modules/dnaObject/SQLLab/duck/sqlLabUtils';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useLibraryObjectImportSqlLabMutation, useLibraryObjectvalidateSqlLabMutation } from '../duck/libraryObjectApi';

const LibraryObjectModalImportSqlLabContent = ({ onClose, t }: ILibraryObjectModalImportSqlLabContentProps) => {
  const [importLibrarySQLLab] = useLibraryObjectImportSqlLabMutation();
  const [validateSqlLab] = useLibraryObjectvalidateSqlLabMutation();
  const confirmModal = useConfirmModal();
  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);

  const onImport: ISQLLabObjectSelectFormProps['onSubmit'] = async (
    values: IDataset[] | ISQLLabObject[],
    { systemInfo, overwrite },
  ) => {
    if (overwrite) {
      try {
        await validateSqlLab({ sql_lab_names: values.map((item) => item.name) }).unwrap();

        await importLibrarySQLLab({
          data: prepareSQLLabObjectForImport(values, systemInfo!),
          overwrite: overwrite,
        }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;

        confirmModal.openConfirmation({
          content: (
            <div style={{ width: '100%' }}>
              {t('confirmOverwrite.content')}
              <strong>{(Object.keys(data.rawData?.error) || []).join(', ')}</strong>
            </div>
          ),
          data: prepareSQLLabObjectForImport(values, systemInfo!),
          cascadeObjects: data.rawData?.error || {},
        });

        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await importLibrarySQLLab({
        data: prepareSQLLabObjectForImport(values, systemInfo!),
        overwrite,
      }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await importLibrarySQLLab({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <SQLLabObjectSelectForm
        onClose={onClose}
        onSubmit={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
      >
        <LibraryCascadeObjects
          data={confirmModal.confirmState?.cascadeObjects}
          isCascade={isCascade}
          setIsCascade={setIsCascade}
          setSelectedCascadeObjects={setSelectedCascadeObjects}
        />
      </ConfirmModal>
    </>
  );
};

export const LibraryObjectModalImportSqlLab = ({ open, data, onClose }: ILibraryObjectModalImportSqlLabProps) => {
  const { t } = useTranslation(['libraryObject']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('sqlLab.importForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <LibraryObjectModalImportSqlLabContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryObjectModalImportSqlLabProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface ILibraryObjectModalImportSqlLabContentProps
  extends Pick<ILibraryObjectModalImportSqlLabProps, 'data' | 'onClose'> {
  t: TFunction;
}
