import { ViewerModalsPushModalPayload, ViewerModalsState } from '@modules/viewer/modals';
import { Task, ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { ISnapshot } from '@modules/snapshot/SnapshotTypes';
import { appActions } from '@app/duck/appSlice';
import { authActions } from '@modules/auth/duck/AuthSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { Key } from 'react';
import { NodeId } from 'react-accessible-treeview';
import { ViewerDataFormatsFormValues } from '../modals/components';

const initialState: ViewerStore = {
  modals: { selectedModal: null },
  groups: {},
  expanded: {},
  leftSide: {},
  snapshot: null,
  tasks: [],
  environment: '',
  dataFormats: {
    dateTimeFormat: '',
  },
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<ViewerModalsPushModalPayload>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
    setDataFormats: (state, action: PayloadAction<ViewerStore['dataFormats']>) => {
      state.dataFormats = action.payload;
    },
    setGroup: (state, action: PayloadAction<ViewerSetGroupPayload>) => {
      const { studyId, group, data } = action.payload;

      state.groups = {
        ...state.groups,
        [studyId]: {
          ...(state.groups[studyId] || {}),
          [state.environment]: {
            ...state.groups[studyId]?.[state.environment],
            [group]: data,
          },
        },
      };
    },
    setLeftSideData: (state, action: PayloadAction<Partial<ILeftSideData>>) => {
      const { tableKey, tableName, tableFolderId } = action.payload;
      state.leftSide = {
        tableKey,
        tableName,
        tableFolderId,
      };
    },
    updateExpanded: (state, action: PayloadAction<ViewerUpdateExpandedPayload>) => {
      const { studyId, keys } = action.payload;
      if (!isEqual(state.expanded[studyId]?.[state.environment], keys)) {
        state.expanded = {
          ...state.expanded,
          [studyId]: { ...state.expanded[studyId], [state.environment]: keys },
        };
      }
    },
    setSnapshot: (state, action: PayloadAction<ISnapshot & { value?: string }>) => {
      state.snapshot = action.payload;
    },
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload;
    },
    addTask: (state, action: PayloadAction<Partial<Task>>) => {
      if (
        state.tasks.every(
          (task) => task.study_id !== action.payload.study_id || task.table_id !== action.payload.table_id,
        )
      ) {
        state.tasks = [...state.tasks, action.payload];
      }
    },
    removeTask: (state, action: PayloadAction<Partial<Task>>) => {
      if (
        state.tasks.some(
          (task) => task.study_id === action.payload.study_id && task.table_id === action.payload.table_id,
        )
      ) {
        state.tasks = state.tasks.filter(
          (task) => task.study_id !== action.payload.study_id || task.table_id !== action.payload.table_id,
        );
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(appActions.setContext, (state, action) => {
        if (!action.payload.context?.sameStudy) {
          state.leftSide = initialState.leftSide;
        }
        state.snapshot = initialState.snapshot;
        state.modals = initialState.modals;
      })
      .addCase(appActions.setENVSwitch, (state, action) => {
        state.environment = action.payload?.env || '';
      })
      .addCase(authActions.setUser, (state, action) => {
        state.environment = action.payload?.env_name || '';
      })
      .addDefaultCase((state, action) => {});
  },
});

export const viewerActions = viewerSlice.actions;

export const viewerReducer = viewerSlice.reducer;

export interface ViewerStore {
  modals: ViewerModalsState;
  groups: Record<string, Record<string, Record<ViewerGroupType, ViewerGroup[]>>>;
  expanded: Record<string, Record<number | string, Key[]>>;
  leftSide: Partial<ILeftSideData>;
  snapshot: (Pick<ISnapshot, 'description' | 'createdAt' | 'tablesDetails' | 'protocolId'> & { value?: string }) | null;
  tasks: Partial<Task>[];
  environment: string;
  dataFormats: ViewerDataFormatsFormValues;
}

export interface ViewerGroup<IDType = number | string> {
  id: IDType;
  name: string;
  tables?: ViewerGroup<string>[];
}

interface ViewerSetGroupPayload {
  studyId: number;
  group: ViewerGroupType;
  data: ViewerGroup[];
}

interface ViewerUpdateExpandedPayload {
  studyId: number;
  keys: Key[];
}

interface ILeftSideData {
  tableKey: string | null;
  tableName: string | null;
  tableFolderId: NodeId | null;
}
