import { InformationModal } from '@components';
import { GlobalModalData } from '@app/modals';

export const NotificationModal = ({ open, data, onClose }: NotificationModalProps) => {
  const { message, description } = data || {};

  return open ? <InformationModal title={message} message={description} onClose={onClose} /> : null;
};

export interface NotificationModalProps {
  open: boolean;
  data: GlobalModalData;
  onClose: () => void;
}
