import { Button, notification, Space } from '@ui';
import { PREFIX_CODE_LAB_DATASET, PREFIX_SQL_LAB_DATASET } from '@shared/GlobalConstants';
import { getFormattedDnaPlatformUrl, getStudyPathName } from '@routes/utils';
import { DeleteDatasetProps, EDatasetStatus, IDataset } from '@modules/dataset/DatasetTypes';
import routes from '@routes';
import { viewerActions } from '@modules/viewer/duck/viewerSlice';
import { MoreButton } from '@components';
import { isCrossStudy } from '@shared/utils/common';
import { QueryErrorType } from '@shared/utils/Error';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useCreateStackDatasetMutation, useDeleteDatasetMutation } from '@modules/dataset/duck/datasetApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { ViewIcon } from '@components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import { datasetActions } from '../duck/datasetSlice';
import { EDatasetModalsType } from '../modals/DatasetModalsConstants';

export const DatasetActionButtons = ({
  record,
  studyId,
  isStackType,
  viewDatasetFolder,
  t,
}: DatasetActionButtonsProps) => {
  const dispatch = useDispatch();
  const [deleteDataset, deleteDatasetQuery] = useDeleteDatasetMutation();
  const [createStackDataset, createStackDatasetQuery] = useCreateStackDatasetMutation();
  const {
    appPermissions: { canDatasetsDelete, canDatasetsInsert, canStackDatasetsDelete, canStackDatasetsInsert },
  } = useAppPermissions();
  const globalStudy = useSelector(selectGlobalStudy);
  const navigate = useNavigate();

  const { id, name } = record;

  const onDeleteDataset = async (value: DeleteDatasetProps) => {
    const { id, name } = value;
    try {
      const isGlobal = isCrossStudy(globalStudy?.id!);
      await deleteDataset(id).unwrap();
      // TODO this new api doesn't work if we use them from datastage. Need to change after backend changes
      // await deleteDataset({ name, isGlobal }).unwrap();
      notification.success({ message: t('rootTable.actions.delete.successDeleteMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('rootTable.actions.delete.errorDeleteMessage', { name }),
        description: (e as QueryErrorType).data.userMsg,
      });
    }
  };

  const onUpdateStackDataset = async () => {
    try {
      await createStackDataset({
        name: record.name,
        scope:
          (isArray(record.scope) ? record.scope.map((item: any) => item?.id.toString()).join(',') : record.scope) ?? '',
      }).unwrap();
      notification.success({
        message: t('rootTable.actions.updateStackDataset.successMessage', { name: record.name }),
      });
    } catch (e) {
      notification.error({
        message: t('rootTable.actions.updateStackDataset.errorMessage', { name: record.name }),
        description: (e as QueryErrorType).data.userMsg,
      });
    }
  };

  const showEditDatasetBtn =
    !isStackType &&
    canDatasetsInsert &&
    (record?.name?.startsWith(PREFIX_SQL_LAB_DATASET) ||
      (record?.name?.startsWith(PREFIX_CODE_LAB_DATASET) && record.file_id));

  const menuItems = isStackType
    ? [
        { key: 'updateDataStack', label: t('rootTable.actions.updateStack'), disabled: !canStackDatasetsInsert },
        { key: 'editStack', label: t('rootTable.actions.edit'), disabled: !canStackDatasetsInsert },
      ]
    : isCrossStudy(globalStudy?.id!)
    ? [{ key: 'edit', label: t('rootTable.actions.edit'), disabled: !showEditDatasetBtn }]
    : [
        {
          key: 'createStack',
          label: t('rootTable.actions.createStackDataset'),
          disabled: !canStackDatasetsInsert || record.status === EDatasetStatus.Error,
        },
        { key: 'edit', label: t('rootTable.actions.edit'), disabled: !showEditDatasetBtn },
      ];

  return (
    <Space>
      <Button
        title={t('rootTable.actions.viewData')}
        icon={<ViewIcon color="darkGrey" />}
        loading={deleteDatasetQuery.isLoading || createStackDatasetQuery.isLoading}
        onClick={() => {
          dispatch(viewerActions.setLeftSideData({}));
          navigate(
            routes[getStudyPathName(studyId)].dataViewer.resolver({
              studyId,
              tableId: name,
              tableFolderName: viewDatasetFolder,
            }),
            { state: { external: true } },
          );
        }}
      />
      <MoreButton
        menu={{
          items: menuItems,
          onClick: async ({ key }) => {
            switch (key) {
              case 'edit':
                let pathToCdrEditDataset = '';
                if (record?.file_id) {
                  pathToCdrEditDataset = getFormattedDnaPlatformUrl({
                    customPath: '/notebooks/',
                    customParams: `file_id=${record.file_id}`,
                  });
                } else {
                  pathToCdrEditDataset = getFormattedDnaPlatformUrl({
                    customPath: '/superset/sqllab/',
                    customParams: `dataset_name=${record.name}&dataset_study_id=${studyId}`,
                  });
                }
                window.open(pathToCdrEditDataset, '_blank');
                break;
              case 'updateDataStack':
                onUpdateStackDataset();
                break;
              case 'editStack':
                dispatch(datasetActions.pushModal({ type: EDatasetModalsType.editStackDataset, data: record }));
                break;
              case 'createStack':
                dispatch(
                  datasetActions.pushModal({ type: EDatasetModalsType.editStackDataset, data: { name: record.name } }),
                );
                break;
              default:
                break;
            }
          },
        }}
        loading={deleteDatasetQuery.isLoading}
        deleteConfig={{
          itemLabel: t('rootTable.actions.delete.title'),
          submitFunc: () => onDeleteDataset({ id, name }),
          disabled: isStackType ? !canStackDatasetsDelete : !canDatasetsDelete,
          confirmTitle: t('rootTable.actions.delete.confirmationDeleteTitle'),
          confirmContent: (
            <div style={{ whiteSpace: 'pre-line' }}>
              {!isCrossStudy(studyId)
                ? t('rootTable.actions.delete.confirmationScopeDelete')
                : t('rootTable.actions.delete.confirmationDelete')}
            </div>
          ),
        }}
      />
    </Space>
  );
};

interface DatasetActionButtonsProps {
  record: IDataset;
  t: TFunction;
  studyId: number;
  isStackType?: boolean;
  viewDatasetFolder?: string;
}
