import { IRoute, TResolverFunc } from '@shared/GlobalTypes';
import { matchRoutes } from 'react-router-dom';

const matchAndGetResolver = (pagePath: string, route?: IRoute): TResolverFunc | null => {
  if (typeof route?.path === 'string' && matchRoutes([{ path: route.path }], pagePath)) {
    return typeof route.resolver === 'function' ? route.resolver : null;
  }
  return null;
};

const findMatchedResolver = (pagePath: string, route: IRoute): TResolverFunc | null => {
  const topLevelResolver = matchAndGetResolver(pagePath, route);
  if (topLevelResolver) return topLevelResolver;

  const rootResolver = 'root' in route ? matchAndGetResolver(pagePath, route.root) : null;
  if (rootResolver) return rootResolver;

  const nestedResolvers = Object.values(route)
    .filter((value) => typeof value === 'object' && value !== null && 'path' in value)
    .map((nestedRoute) => {
      if (nestedRoute.path && matchRoutes([{ path: nestedRoute.path }], pagePath)) {
        if ('root' in route && route.root?.resolver && typeof route.root.resolver === 'function') {
          return route.root.resolver;
        }
        return matchAndGetResolver(pagePath, nestedRoute);
      }
      return findMatchedResolver(pagePath, nestedRoute);
    });
  return nestedResolvers.find((resolver) => resolver !== null) || null;
};

export const getMatchedResolver = (pagePath: string, routesObj: IRoute): TResolverFunc | null =>
  Object.values(routesObj)
    .map((route) => findMatchedResolver(pagePath, route))
    .find((resolver) => resolver !== null) || null;
