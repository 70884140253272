import { NotificationModal, NotificationModalProps } from '@app/modals/components/NotificationModal';
import { GlobalModalsType } from '@app/modals';
import { selectModal } from '@app/duck/appSelectors';
import { appActions } from '@app/duck/appSlice';
import { useDispatch, useSelector } from 'react-redux';

export const GlobalModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectModal);

  const closeModal = () => {
    dispatch(appActions.dropModal());
  };

  return (
    <>
      <NotificationModal
        open={selectedModal === GlobalModalsType.notificationDetails}
        data={data as NotificationModalProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
