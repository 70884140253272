import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getListOption } from '@shared/utils/Table';
import { ActorAndInfo } from '@components';
import { dateToString } from '@shared/utils/Date';
import { Button, Table, Tag, Typography } from '@ui';
import { DocumentSearch } from '@components/icons';
import { SupportedEnvs } from '@app/AppTypes';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import {
  IAnalysisPackageDeployment,
  IAnalysisPackageDeploymentListResponse,
  DeploymentStatus,
} from '../AnalysisPackageTypes';
import { AnalysisPackageDeploymentStatus } from '../duck/analysisPackageConstants';

export const AnalysisPackageDeploymentList = ({
  data,
  environments,
  pagination,
  loading,
  supportedEnvs,
  onChange,
  onOpenLog,
  onOpenPackage,
  statuses,
  t,
}: IAnalysisPackageDeploymentListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisPackageDeploymentListResponse['items'][0]>();

  const environmentOptions = getListOption(environments).map((el) => ({
    ...el,
    text: supportedEnvs[el.value]?.label || t('na'),
  }));

  const statusOptions = Object.entries(AnalysisPackageDeploymentStatus).map(([key, value]) => ({
    text: t(`deployments.status.${value}`),
    value: key,
  }));

  const getStatusById = (statuses: DeploymentStatus[], id: IAnalysisPackageDeployment['id']) =>
    statuses?.find((el) => el.id === id);

  const columns: TableColumnsType<IAnalysisPackageDeploymentListResponse['items'][0]> = useMemo(
    () => [
      {
        width: 160,
        title: t('deployments.rootTable.version'),
        dataIndex: 'version',
        ...getColumnSearchProps(['package', 'version']),
        onFilter: undefined,
        render: (version, record) => (
          <Typography.Link onClick={() => onOpenPackage(record.package)} children={record.package.version} />
        ),
      },
      {
        title: t('deployments.rootTable.env'),
        filters: environmentOptions,
        dataIndex: 'environment',
        onFilter: undefined,
        render: (environment: string) => supportedEnvs[environment]?.label || t('na'),
      },
      {
        title: t('deployments.rootTable.started'),
        key: 'narrow',
        dataIndex: 'started_at',
        sorter: () => 0,
        sortDirections: ['ascend'],
        render: (startedAt: number, record) => (
          <ActorAndInfo info={dateToString(startedAt)} actor={record.created_by} />
        ),
      },
      {
        width: 150,
        title: t('deployments.rootTable.status'),
        dataIndex: 'status',
        filters: statusOptions,
        onFilter: undefined,
        render: (_, record) => {
          const status = getStatusById(statuses, record.id)?.status;
          return <Tag color={status}>{status}</Tag>;
        },
      },
      {
        width: 100,
        title: t('deployments.rootTable.log'),
        dataIndex: 'deployPackage',
        render: (_, record) => (
          <Button
            title={t('deployments.rootTable.log')}
            size="middle"
            icon={<DocumentSearch />}
            onClick={() => onOpenLog({ ...record, log: getStatusById(statuses, record.id)?.log ?? record.log })}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environmentOptions, statusOptions, supportedEnvs, statuses],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 800 }}
      pagination={pagination}
    />
  );
};

export interface IAnalysisPackageDeploymentListProps {
  supportedEnvs: SupportedEnvs;
  studyId: number;
  data?: IAnalysisPackageDeploymentListResponse['items'];
  environments: string[];
  pagination?: TableProps<IAnalysisPackageDeploymentListResponse['items'][0]>['pagination'];
  onChange?: TableProps<IAnalysisPackageDeploymentListResponse['items'][0]>['onChange'];
  onOpenLog: (data: IAnalysisPackageDeployment) => void;
  onOpenPackage: (data: { id: number }) => void;
  loading?: boolean;
  t: TFunction;
  statuses: DeploymentStatus[];
}
