import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { libraryObjectActions } from '@modules/library/libraryObject/duck/libraryObjectSlice';
import { ELibraryObjectModalsType } from '@modules/library/libraryObject/libraryObjectTypes';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { CodeLabObjectList, CodeLabObjectListProps } from '@modules/dnaObject/CodeLab/components';
import { ICodeLabObject } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLibraryObjectDeleteCodeLabMutation, useLibraryObjectCodeLabListQuery } from '../duck/libraryObjectApi';

export const LibraryObjectCodeLabRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryObject']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ICodeLabObject>(initialPage);
  const codeLabQuery = useLibraryObjectCodeLabListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteCodeLab, deleteCodeLabQuery] = useLibraryObjectDeleteCodeLabMutation();

  const {
    appPermissions: { canGlSloInsert, canGlLibraryUpdate, canGlSloDelete },
  } = useAppPermissions();

  const pagination = getPagination(codeLabQuery.data?.totalItems);

  const onImportCodeLabModal = () =>
    dispatch(libraryObjectActions.pushModal({ type: ELibraryObjectModalsType.importCodeLab }));

  const onDelete: CodeLabObjectListProps['onDelete'] = async (value) => {
    const { id, name } = value;
    try {
      await deleteCodeLab(id).unwrap();
      notification.success({ message: t('codeLabObject.confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('codeLabObject.confirmation.errorMessage', { name }) });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        level: 2,
        children: t('codeLabObject.pageRootName'),
        refetch: codeLabQuery.refetch,
        extra: libraryIsEditable && canGlSloInsert && <Button children={t('import')} onClick={onImportCodeLabModal} />,
      }}
      content={{
        isLoading: codeLabQuery.isLoading && !codeLabQuery.data,
        errorText: t('codeLabObject.loadingError'),
        error: codeLabQuery.error,
      }}
    >
      {!codeLabQuery.isLoading && (
        <CodeLabObjectList
          data={codeLabQuery.data}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlSloDelete ? onDelete : undefined}
          pagination={pagination}
          loading={codeLabQuery.isFetching}
          loadingDelete={deleteCodeLabQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
