import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button, notification } from '@ui';
import { initialPage } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { TLibraryCdrDeleteProps } from '@modules/library/cdr/LibraryCdrTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { IAnalysisObjectCDRListResponse } from '@modules/library/analysisObjects/cdr/AnalysisObjectCDRTypes';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useAnalysisObjectCDRListQuery,
  useAnalysisObjectCDRFiltersQuery,
  useDeleteAnalysisObjectCDRMutation,
} from '../duck/analysisObjectCDRApi';
import { AnalysisObjectCDRList } from '../components';
import { analysisObjectCDRActions } from '../duck/analysisObjectCDRSlice';
import { EAnalysisObjectCDRModalsType } from '../modals/AnalysisObjectCDRModalsConstants';
import { AnalysisObjectCDRModalsController } from '../modals/AnalysisObjectCDRModalsController';

export const AnalysisObjectCDRRoot = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryCdr']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisObjectCDRListResponse['items'][0]>(initialPage);

  const {
    userPermissions: { canGlCdrAoImport },
  } = useStudyPermissions();

  const dataCDRQuery = useAnalysisObjectCDRListQuery({
    detailed: '1',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const cdrFilters = useAnalysisObjectCDRFiltersQuery();

  const [deleteCDR, deleteCDRQuery] = useDeleteAnalysisObjectCDRMutation();

  const pagination = getPagination(dataCDRQuery.data?.totalItems);

  const copyDataModal = () =>
    dispatch(analysisObjectCDRActions.pushModal({ type: EAnalysisObjectCDRModalsType.copyCDR }));

  const onDeleteCDR = async (value: TLibraryCdrDeleteProps) => {
    const { id, name } = value;
    try {
      await deleteCDR(id).unwrap();
      notification.success({ message: t('confirmationAO.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  return (
    <PageTemplateSimple
      content={{
        isLoading: dataCDRQuery.isLoading && !dataCDRQuery.data,
        errorText: t('loadingError'),
        error: dataCDRQuery.error,
      }}
      title={{
        children: t('pageRootName'),
        pageTitle: t('pageTitle'),
        refetch: dataCDRQuery.refetch,
        extra: canGlCdrAoImport && <Button children={t('import')} onClick={copyDataModal} />,
      }}
    >
      {dataCDRQuery.data && (
        <AnalysisObjectCDRList
          data={dataCDRQuery.data}
          sources={cdrFilters?.data?.sources ?? []}
          onChange={onTableChange}
          pagination={pagination}
          loading={dataCDRQuery.isFetching}
          onDelete={onDeleteCDR}
          loadingDelete={deleteCDRQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
      <AnalysisObjectCDRModalsController />
    </PageTemplateSimple>
  );
};
