import { useColumnSearch } from '@components/ui/table/tableHooks';
import { parseCodeLabFilePath } from '@modules/codeLab/components/CodeLabFilePath';
import { Tooltip } from '@ui';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICodeLabObject } from '../CodeLabObjectTypes';

export const useCodeLabObjectColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<ICodeLabObject>();
  const { t } = useTranslation(['dnaObject']);

  const codeLabColumns: TableColumnsType<ICodeLabObject> = useMemo(
    () => [
      {
        title: t('codeLabObject.rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        ...getColumnSearchProps('name'),
        render: (_, record) => (
          <Tooltip placement="top" title={parseCodeLabFilePath(record.configuration?.file_path || '')}>
            {record.name}
          </Tooltip>
        ),
      },
      {
        width: 120,
        key: 'libraryVersion',
        title: t('codeLabObject.rootTable.libraryVersion'),
        dataIndex: 'version',
        render: (_, record) => (Array.isArray(record.library_id) && record.library_id.length ? record.version : '-'),
      },
      {
        width: 100,
        title: t('codeLabObject.rootTable.source'),
        key: 'source',
        dataIndex: 'source',
        render: (_, record) => record?.source || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { codeLabColumns, locale };
};
