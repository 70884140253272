import { Space, Tag } from '@ui';
import { ChevronDownIcon } from '@components/icons';
import { LibraryTagActionMenu } from '@modules/library/root/components/LibraryTagActionMenu';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LibraryStatus } from '../duck/libraryConstants';

export const LibraryStatusTag = ({ status, calm = false, small = false, withMenu = false }: LibraryStatusTagProps) => {
  const { t } = useTranslation(['libraryRoot']);
  const globalLibrary = useSelector(selectGlobalLibrary);

  const {
    appPermissions: { canGlLibraryUpdate },
  } = useAppPermissions();

  const colors = calm
    ? {
        [LibraryStatus.Active]: 'green',
        [LibraryStatus.Development]: 'blue',
        [LibraryStatus.Retired]: 'red',
      }
    : {
        [LibraryStatus.Active]: 'success',
        [LibraryStatus.Development]: 'processing',
        [LibraryStatus.Retired]: 'error',
      };

  if (status) {
    if (canGlLibraryUpdate && withMenu) {
      return (
        <LibraryTagActionMenu
          record={{ id: globalLibrary?.id!, name: globalLibrary?.name!, status }}
          t={t}
          children={
            <Tag color={colors[status]} bordered={!calm} css={small ? cssSmall : undefined}>
              <Space>
                {t(`status.${status}`)}
                <ChevronDownIcon css={{ fontSize: 24 }} />
              </Space>
            </Tag>
          }
        />
      );
    }

    return (
      <Tag color={colors[status]} bordered={!calm} css={small ? cssSmall : undefined}>
        {t(`status.${status}`)}
      </Tag>
    );
  }

  return null;
};

const cssSmall = css({
  fontSize: '80%',
});

type LibraryStatusTagProps = {
  status?: LibraryStatus;
  calm?: boolean;
  small?: boolean;
  withMenu?: boolean;
};
