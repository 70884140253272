import { IAnalysisPackageDeployment } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { DraggableModal, Typography } from '@ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const AnalysisPackageModalsDeploymentLogContent = ({ data }: AnalysisPackageModalsDeploymentLogContentProps) => {
  const { t } = useTranslation(['analysisPackage']);

  return (
    <Typography.Paragraph>
      <pre>{data?.log || t('deployments.noLogData')}</pre>
    </Typography.Paragraph>
  );
};

export const AnalysisPackageModalsDeploymentLog = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsDeploymentLogProps) => {
  const { t } = useTranslation(['analysisPackage']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('deploymentLogModal.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisPackageModalsDeploymentLogContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};
export interface AnalysisPackageModalsDeploymentLogProps {
  open: boolean;
  data?: IAnalysisPackageDeployment;
  onClose: () => void;
}

interface AnalysisPackageModalsDeploymentLogContentProps
  extends Pick<AnalysisPackageModalsDeploymentLogProps, 'data' | 'onClose'> {
  t: TFunction;
}
