import { TabProps } from '@modules/job/JobTypes';
import { Alert, Button, Checkbox, FormItem, Select, Space, Typography } from '@ui';
import { AntdIconBox } from '@components/icons';
import { emptyError } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { DataTable } from '@components';
import {
  isDateTimeType,
  isMandatoryUploadDateTimeColumns,
  isSystemColumn,
  typeOptions,
} from '@modules/job/modals/components/upload/duck/uploadUtils';
import { RequiredDateTimeFieldsPopup } from '@modules/job/modals/components/upload/steps/components/dateTimePopup/RequiredDateTimeFieldsPopup';
import { MandatoryColumnsWithAutogeneration } from '@config/constants';
import { TFunction } from 'i18next';
import { useEffect, useMemo } from 'react';
import { CSSObject } from '@emotion/react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RenderCellProps } from 'react-data-grid';
import { Dayjs } from 'dayjs';

export const DiscoverTabManual = ({
  t,
  tab,
  canImportJobEditStructure,
  updateTabPartially,
  handleTabConfirm,
  form,
}: DiscoverTabProps) => {
  const { tableName, mapping, isMappingExists, isHideConfirmBtn, missingRequiredColumns } = tab;
  const isDisableMapping = !canImportJobEditStructure && isMappingExists;
  const missingAutogeneratedColumns = missingRequiredColumns.filter((column) =>
    MandatoryColumnsWithAutogeneration.includes(column),
  );

  const handlesColumnChange = (record: any, field: string, value?: string | Dayjs | null, index?: number) => {
    const updatedMappingTable = mapping.map((el) =>
      (el.sourceColumn || el.sourceMappingColumn) === (record.sourceColumn || record.sourceMappingColumn)
        ? {
            ...el,
            [field]: value || !record[field],
          }
        : el,
    );
    updateTabPartially(tableName, { mapping: updatedMappingTable, confirmed: false, ...emptyError });
  };

  useEffect(() => {
    if (mapping !== undefined) {
      form.setFieldsValue({
        [tableName]: {
          mapping,
        },
      });
    }
  }, [mapping, form, tableName]);

  const columns = useMemo(
    () => [
      {
        width: 60,
        key: 'skip',
        name: t('uploadRT.mapping.skip'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.skip}
              disabled={
                isSystemColumn(row.sourceColumn!) ||
                isMandatoryUploadDateTimeColumns(row.sourceColumn!) ||
                isDisableMapping
              }
              onChange={() => handlesColumnChange(row, 'skip')}
            />
          </div>
        ),
      },
      {
        minWidth: 250,
        key: 'sourceColumn',
        name: t('uploadRT.mapping.sourceColumn'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => {
          if (!row.sourceMappingColumn) {
            return (
              <Space direction="horizontal">
                {row.sourceColumn}
                <AntdIconBox icon={QuestionCircleOutlined} tip={t('uploadRT.mappingTooltips.columnAbsentInMapping')} />
              </Space>
            );
          }

          const getRequiredFieldTooltip =
            row.existInFile && !isDateTimeType(row.type)
              ? t('uploadRT.mappingTooltips.columnRequiredButNotCorrect')
              : t('uploadRT.mappingTooltips.columnRequired');

          const getFieldTooltip = canImportJobEditStructure
            ? t('uploadRT.mappingTooltips.columnAbsentInFileAdmin')
            : t('uploadRT.mappingTooltips.columnAbsentInFile');

          if (
            !row.sourceColumn ||
            (isMandatoryUploadDateTimeColumns(row.sourceColumn!) && !isDateTimeType(row.type) && !row.value)
          ) {
            return (
              <Space direction="horizontal">
                <Typography.Text type="danger" children={row.sourceMappingColumn} />
                <AntdIconBox
                  icon={QuestionCircleOutlined}
                  tip={isMandatoryUploadDateTimeColumns(row.sourceColumn!) ? getRequiredFieldTooltip : getFieldTooltip}
                  color="danger"
                />
              </Space>
            );
          }

          return row.sourceColumn;
        },
      },
      {
        minWidth: 100,
        key: 'type',
        name: t('uploadRT.mapping.type'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => {
          const index = mapping.findIndex((el) => row.sourceColumn === el.sourceColumn);

          return !isMandatoryUploadDateTimeColumns(row.sourceColumn!) ||
            (isMandatoryUploadDateTimeColumns(row.sourceColumn!) && isDateTimeType(row.type) && row.existInFile) ? (
            <FormItem
              name={[tableName, 'structure', row.sourceColumn!]}
              wrapperCol={{ span: 24 }}
              css={cssTableInput}
              label={null}
              rules={[{ required: true, message: '' }]}
            >
              <Select
                options={typeOptions}
                onChange={(value) => handlesColumnChange(row, 'type', value)}
                disabled={
                  isSystemColumn(row.sourceColumn!) ||
                  isMandatoryUploadDateTimeColumns(row.sourceColumn!) ||
                  isDisableMapping
                }
                onClick={(e) => e.stopPropagation()}
              />
            </FormItem>
          ) : (
            <RequiredDateTimeFieldsPopup
              row={row}
              index={index}
              tab={tab}
              updateTabPartially={updateTabPartially}
              t={t}
            />
          );
        },
      },
      {
        width: 96,
        key: 'nullable',
        name: t('uploadRT.mapping.nullable'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.nullable}
              onChange={() => handlesColumnChange(row, 'nullable')}
              disabled={
                isSystemColumn(row.sourceColumn!) ||
                isMandatoryUploadDateTimeColumns(row.sourceColumn!) ||
                isDisableMapping
              }
            />
          </div>
        ),
      },
      {
        width: 96,
        key: 'primaryKey',
        name: t('uploadRT.mapping.primaryKey'),
        renderCell: ({ row }: RenderCellProps<TabProps['mapping'][0]>) => (
          <div css={cssCheckbox}>
            <Checkbox
              checked={row.primaryKey}
              onChange={() => handlesColumnChange(row, 'primaryKey')}
              disabled={isDisableMapping}
            />
          </div>
        ),
      },
    ],
    [tableName, isDisableMapping, handlesColumnChange, canImportJobEditStructure],
  );

  const rowKeyGetter = (item: TabProps['mapping'][0]) =>
    `${tableName}_${item.sourceColumn || item.sourceMappingColumn}`;

  return (
    <>
      <div css={cssTabHeaderContainer(isMappingExists || missingAutogeneratedColumns.length > 0)}>
        <div style={{ marginRight: 8 }}>
          {isMappingExists && (
            <Alert
              css={cssAlert}
              type="warning"
              message={canImportJobEditStructure ? t('uploadRT.mappingExistsMsgAdmin') : t('uploadRT.mappingExistsMsg')}
            />
          )}
          {missingAutogeneratedColumns.length > 0 && (
            <Alert
              css={cssAlert}
              type="warning"
              message={t('uploadRT.missingAutogeneratedColumns', { list: missingAutogeneratedColumns.join(', ') })}
            />
          )}
        </div>
        {!isHideConfirmBtn && (
          <Button
            type="primary"
            onClick={() => {
              handleTabConfirm(tableName);
            }}
          >
            {t('uploadRT.confirmTabBtn')}
          </Button>
        )}
      </div>
      <div css={cssMappingTable}>
        {mapping && (
          <DataTable
            rowKeyGetter={rowKeyGetter}
            rowHeight={56}
            headerRowHeight={40}
            columns={columns}
            rows={mapping ?? []}
          />
        )}
      </div>
    </>
  );
};

const cssCheckbox = (): CSSObject => ({ textAlign: 'center' });

const cssMappingTable = (): CSSObject => ({
  height: '300px',
  maxHeight: '300px',
  width: '100%',
  paddingBottom: '8px',

  '.rdg-cell': {
    outline: 'none',
    alignContent: 'space-evenly',
  },
});

const cssTableInput = (): CSSObject => ({
  width: '100%',
});

const cssTabHeaderContainer = (warnings: boolean): CSSObject => ({
  width: '100%',
  display: 'flex',
  justifyContent: warnings ? 'space-between' : 'right',
  alignItems: 'center',
  marginBottom: 12,
});

const cssAlert = (): CSSObject => ({
  padding: '4px 12px',
});

interface DiscoverTabProps {
  t: TFunction;
  tab: TabProps;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  handleTabConfirm: (val: string) => void;
  canImportJobEditStructure?: boolean;
  form?: any;
}
