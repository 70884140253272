import { TabProps } from '@modules/job/JobTypes';
import { AutoComplete, DatePicker, Divider, FormItem, Input, Popover, Radio, Space, Typography } from '@ui';
import { DATE_FORMAT, emptyError, TIME_FORMAT } from '@modules/job/modals/components/upload/duck/uploadConstants';
import {
  checkFieldFormatValue,
  filterDateFormats,
  getDateTimeOptions,
} from '@modules/job/modals/components/upload/duck/uploadUtils';
import { ApplyButton } from '@modules/job/modals/components/upload/steps/components/dateTimePopup/ApplyButtonPopup';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CSSObject } from '@emotion/react';

const DATE_TEMPLATE = '2024-12-31 23:55:30';

export const RequiredDateTimeFieldsPopup = ({
  tab,
  row,
  index,
  updateTabPartially,
  t,
}: RequiredDateTimeFieldsProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { tableName, mapping } = tab;
  const form = useFormInstance();

  const handlePopoverClose = () => {
    setPopoverVisible(false);
  };

  const handlesColumnChangeIndex = (values?: FormChangeValues, index?: number) => {
    const updatedMappingTable = [...mapping];
    if (index !== undefined) {
      updatedMappingTable[index] = {
        ...updatedMappingTable[index],
        ...values,
      };
    }
    updateTabPartially(tableName, { mapping: updatedMappingTable, confirmed: false, ...emptyError });
  };

  const datePickerField = (
    <FormItem
      name={[tableName, 'mapping', index, 'valueDatePicker']}
      label={null}
      rules={[{ required: true, message: '' }]}
    >
      <DatePicker
        showTime={{
          format: TIME_FORMAT,
        }}
        format={DATE_FORMAT}
      />
    </FormItem>
  );

  const convertFormatField = (
    <FormItem
      name={[tableName, 'mapping', index, 'format']}
      label={null}
      rules={[
        { required: true, message: '' },
        {
          validator: (_, value) => {
            const isValid = checkFieldFormatValue(tab?.sample, 'MODIFIEDDATETIME', value);
            return isValid ? Promise.resolve() : Promise.reject(new Error(t('uploadRT.invalidFormat')));
          },
        },
      ]}
    >
      <AutoComplete
        // TODO after DNA-4677 use instead 'MODIFIEDDATETIME' value from constant
        options={getDateTimeOptions(tab?.sample, 'MODIFIEDDATETIME').map((option) => ({
          ...option,
          label: (
            <div>
              <div>{option.label}</div>
              <Typography.Text type="secondary">{dayjs(DATE_TEMPLATE).format(option.value)}</Typography.Text>
              <Divider style={{ margin: 2 }} />
            </div>
          ),
        }))}
        placeholder={t('uploadRT.convertFormatPlaceholder')}
        notFoundContent={t('uploadRT.noSuitableFormats')}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Typography.Text type="secondary" style={{ padding: '8px' }}>
              {t('uploadRT.convertFormatDropdownTip')}
            </Typography.Text>
          </>
        )}
      />
    </FormItem>
  );

  useEffect(() => {
    const valueExists = form.getFieldValue([tableName, 'mapping', index, 'value']);
    if (popoverVisible && !valueExists) {
      form.setFieldsValue({
        [tableName]: {
          mapping: {
            [index]: {
              convert: true,
            },
          },
        },
      });
    }
  }, [popoverVisible, form.getFieldValue([tableName, 'mapping', index, 'value'])]);

  return row.existInFile ? (
    <Popover
      content={() => (
        <Space full direction="vertical" css={cssContainer}>
          <FormItem name={[tableName, 'mapping', index, 'convert']} wrapperCol={{ span: 24 }}>
            <Radio.Group>
              <Space full direction="vertical">
                <Radio value={true}>{t('uploadRT.tryConvert')}</Radio>
                <Radio value={false}>{t('uploadRT.tryOverwrite')}</Radio>
              </Space>
            </Radio.Group>
          </FormItem>

          <FormItem
            dependencies={[[tableName, 'mapping', index, 'convert']]}
            wrapperCol={{ span: 24 }}
            vertical
            style={{ textAlign: 'right' }}
          >
            {(form) => {
              const isConvertDate = form.getFieldValue([tableName, 'mapping', index, 'convert']);
              form.setFieldValue([tableName, 'mapping', index, 'valueDatePicker'], dayjs());

              // TODO hide this functionality (isConvertDate ? convertFormatField : datePickerField),
              //  convertFormatField is not needed for now). Was resolved by backend
              const renderField = (isConvertDate: boolean) => !isConvertDate && datePickerField;

              return (
                <>
                  {renderField(isConvertDate)}
                  <ApplyButton
                    isConvertDate={isConvertDate}
                    tableName={tableName}
                    index={index}
                    handlesColumnChangeIndex={handlesColumnChangeIndex}
                    handlePopoverClose={handlePopoverClose}
                    t={t}
                  />
                </>
              );
            }}
          </FormItem>
        </Space>
      )}
      title={t('uploadRT.selectOptions')}
      trigger="click"
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
    >
      <FormItem
        name={[tableName, 'mapping', index, 'customTypeValue']}
        wrapperCol={{ span: 24 }}
        css={cssTableInput}
        label={null}
      >
        <Input readOnly placeholder={t('uploadRT.selectOptionPlaceholder')} onClick={() => setPopoverVisible(true)} />
      </FormItem>
    </Popover>
  ) : (
    <FormItem
      name={[tableName, 'mapping', index, 'value']}
      wrapperCol={{ span: 24 }}
      css={cssTableInput}
      label={null}
      getValueProps={(value) => ({
        value: value ? dayjs(value) : undefined,
      })}
      rules={[{ required: true, message: '' }]}
    >
      <DatePicker
        showTime={{
          format: TIME_FORMAT,
        }}
        showNow
        format={DATE_FORMAT}
        onChange={(value) => handlesColumnChangeIndex({ value: value?.toISOString() }, index)}
        onClick={(e) => e.stopPropagation()}
      />
    </FormItem>
  );
};

const cssTableInput = (): CSSObject => ({
  width: '100%',
});

const cssContainer = (): CSSObject => ({
  '& .ant-space-item:last-child': {
    display: 'inline',
  },
  '& .ant-form-item-explain-error': {
    textAlign: 'left',
  },
  width: 300,
});

interface RequiredDateTimeFieldsProps {
  row: TabProps['mapping'][0];
  index: number;
  tab: TabProps;
  updateTabPartially: (val: string, obj: Partial<TabProps>) => void;
  t: TFunction;
}

export interface FormChangeValues {
  value?: string;
  convert?: boolean;
  format?: string;
  customTypeValue?: string;
}
