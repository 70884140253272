import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { librarySqlQueryActions } from '@modules/library/sqlQuery/duck/librarySqlQuerySlice';
import { ELibrarySqlQueryModalsType } from '@modules/library/sqlQuery/modals';
import { ILibrarySqlQuery, TLibrarySqlQueryDeleteProps } from '@modules/library/sqlQuery/LibrarySqlQueryTypes';
import {
  useLibraryDeleteSqlQueryMutation,
  useLibrarySqlQueryListQuery,
} from '@modules/library/sqlQuery/duck/librarySqlQueryApi';
import { LibrarySqlQueryList } from '@modules/library/sqlQuery/components/LibrarySqlLabList';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibrarySqlQueryRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['librarySqlQuery']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibrarySqlQuery>(initialPage);
  const sqlQueryReportQuery = useLibrarySqlQueryListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteSqlQuery, deleteSqlQueryQuery] = useLibraryDeleteSqlQueryMutation();

  const {
    appPermissions: { canGlSqoInsert, canGlLibraryUpdate, canGlSqoDelete },
  } = useAppPermissions();

  const pagination = getPagination(sqlQueryReportQuery.data?.totalItems);

  const copySqlQuery = () =>
    dispatch(librarySqlQueryActions.pushModal({ type: ELibrarySqlQueryModalsType.copySqlQuery }));

  const onDelete = async (value: TLibrarySqlQueryDeleteProps) => {
    const { id, name } = value;
    try {
      await deleteSqlQuery(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('confirmation.errorMessage', { name }) });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        children: t('pageRootName'),
        refetch: sqlQueryReportQuery.refetch,
        extra: libraryIsEditable && canGlSqoInsert && <Button children={t('import')} onClick={copySqlQuery} />,
      }}
      content={{
        isLoading: sqlQueryReportQuery.isLoading && !sqlQueryReportQuery.data,
        errorText: t('loadingError'),
        error: sqlQueryReportQuery.error,
      }}
    >
      {!sqlQueryReportQuery.isLoading && (
        <LibrarySqlQueryList
          data={sqlQueryReportQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlSqoDelete ? onDelete : undefined}
          pagination={pagination}
          loading={sqlQueryReportQuery.isFetching}
          loadingDelete={deleteSqlQueryQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
