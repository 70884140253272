import { Button, Dropdown, notification } from '@ui';
import { useSaveLibraryMutation } from '@modules/library/root/duck/libraryApi';
import { ConfirmModal, useConfirmModal } from '@components';
import { Library } from '@modules/library/root/LibraryTypes';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ReactNode, useState } from 'react';
import { CheckCircleOutlined, CloudUploadOutlined, ContainerOutlined, MoreOutlined } from '@ant-design/icons';
import { CSSObject, Theme } from '@emotion/react';
import { TFunction } from 'i18next';

export const LibraryTagActionMenu = ({ record, t, children, isButton = false }: ILibraryActionMenuProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const confirmModal = useConfirmModal();

  const [saveLibrary] = useSaveLibraryMutation();

  const handleConfirm = async () => {
    const status = confirmModal.confirmState.data.status;

    try {
      await saveLibrary({ id: record.id, status });
      notification.success({
        message: t('libraryRoot.root.statusChangedSuccess'),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('libraryRoot.root.statusChangedError'),
      });
    }
  };

  const onClickHandle = ({ content, status, title }: { content: string; status: LibraryStatus; title: string }) => {
    confirmModal.openConfirmation({
      content,
      data: { status, title },
    });
  };

  const items = [
    {
      key: 'moveToDev',
      label: t('root.table.actionMenu.moveToDev'),
      icon: <CloudUploadOutlined />,
    },
    {
      key: 'moveToArchive',
      label: t('root.table.actionMenu.moveToArchive'),
      icon: <ContainerOutlined />,
    },
    {
      key: 'approve',
      label: t('root.table.actionMenu.approve'),
      icon: <CheckCircleOutlined />,
    },
  ];

  const getMenuItems = (status: LibraryStatus) => {
    switch (status) {
      case LibraryStatus.Active:
        return [items[0], items[1]];
      case LibraryStatus.Development:
        return [items[2], items[1]];
      case LibraryStatus.Retired:
        return [items[0]];
      default:
        return [];
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: getMenuItems(record.status),
          onClick: ({ key }) => {
            switch (key) {
              case 'moveToDev':
                onClickHandle({
                  content: t('root.table.actionMenu.confirmation.moveToDevDescription', { name: record.name }),
                  status: LibraryStatus.Development,
                  title: t('root.table.actionMenu.moveToDev'),
                });
                break;
              case 'moveToArchive':
                onClickHandle({
                  content: t('root.table.actionMenu.confirmation.moveToArchiveDescription', { name: record.name }),
                  status: LibraryStatus.Retired,
                  title: t('root.table.actionMenu.moveToArchive'),
                });
                break;
              case 'approve':
                onClickHandle({
                  content: t('root.table.actionMenu.confirmation.approveDescription', { name: record.name }),
                  status: LibraryStatus.Active,
                  title: t('root.table.actionMenu.approve'),
                });
                break;
              default:
                break;
            }
          },
        }}
        open={openDropdown}
        trigger={['click']}
        autoAdjustOverflow={false}
        onOpenChange={(open) => setOpenDropdown(open)}
      >
        {isButton ? (
          <Button
            css={cssActionBtn}
            icon={<MoreOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        ) : (
          children
        )}
      </Dropdown>
      {confirmModal && (
        <ConfirmModal
          title={confirmModal.confirmState.data?.title ?? t('statusModalTitle')}
          submitFunc={handleConfirm}
          okText={t('yes')}
          {...confirmModal}
        />
      )}
    </>
  );
};

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  color: theme.colorLink,
  cursor: 'pointer',
});

interface ILibraryActionMenuProps {
  record: Pick<Library, 'id' | 'name' | 'status'>;
  t: TFunction;
  children?: ReactNode;
  isButton?: boolean;
}
