import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { QueryErrorType } from '@shared/utils/Error';
import { ILibraryCDR } from '@modules/library/cdr/LibraryCdrTypes';
import { TLibraryDeleteModelProps } from '@modules/library/model/LibraryModelTypes';
import { useLibraryCdrListQuery, useLibraryDeleteCdrMutation } from '@modules/library/cdr/duck/libraryCdrApi';
import { LibraryCdrList } from '@modules/library/cdr/components/LibraryCdrList';
import { libraryCdrActions } from '@modules/library/cdr/duck/libraryCdrSlice';
import { ELibraryCdrModalsType } from '@modules/library/cdr/modals';
import { NotificationErrorDescription, PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibraryCdrRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryCdr']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibraryCDR>(initialPage);
  const cdrReportQuery = useLibraryCdrListQuery({
    detailed: '1',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteCdrReport, deleteCdrReportQuery] = useLibraryDeleteCdrMutation();

  const {
    appPermissions: { canGlCdroInsert, canGlLibraryUpdate, canGlCdroDelete },
  } = useAppPermissions();

  const pagination = getPagination(cdrReportQuery.data?.totalItems);

  const copyCdr = () => dispatch(libraryCdrActions.pushModal({ type: ELibraryCdrModalsType.copyCdrReport }));

  const onDelete = async (value: TLibraryDeleteModelProps) => {
    const { id, name } = value;
    try {
      await deleteCdrReport(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t('confirmation.errorMessage', { name }),
        description: <NotificationErrorDescription error={e as QueryErrorType} />,
        duration: 0,
      });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        level: 2,
        children: t('pageRootName'),
        refetch: cdrReportQuery.refetch,
        extra: libraryIsEditable && canGlCdroInsert && <Button children={t('import')} onClick={copyCdr} />,
      }}
      content={{
        isLoading: cdrReportQuery.isLoading && !cdrReportQuery.data,
        errorText: t('loadingError'),
        error: cdrReportQuery.error,
      }}
    >
      {!cdrReportQuery.isLoading && (
        <LibraryCdrList
          data={cdrReportQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlCdroDelete ? onDelete : undefined}
          pagination={pagination}
          loading={cdrReportQuery.isFetching}
          loadingDelete={deleteCdrReportQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
