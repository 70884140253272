import { Button, Space, Spin, Tooltip, Typography } from '@ui';
import { selectAppENV } from '@app/duck/appSelectors';
import { Tag } from '@ui';
import { Refetch } from '@components/icons';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { css, CSSObject, Theme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { ChangeEvent } from 'react';

export const TableListSiderToolbar = ({
  isLoadingData,
  reloadTables,
  isErrorLoadingTables,
  setSearch,
}: TableListSiderToolbarProps) => {
  const appEnv = useSelector(selectAppENV);
  const { t } = useTranslation(['viewer']);

  const onSearchHandled = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <Space full block justify="space-between" direction="vertical" css={cssContainer}>
      <Space justify="space-between" full css={cssInnerContainer}>
        <Space>
          <Typography.Text children={t('sider.title')} css={cssTitle} />
          {appEnv?.current === false && <Tag css={cssEnvTag} type="source" text={appEnv?.label} upperCaseText />}
        </Space>
        <Space>
          {isLoadingData && <Spin css={cssSpinner} indicator={<LoadingOutlined />} size="small" />}
          {!isLoadingData && (
            <Tooltip
              placement="top"
              title={t('sider.loadingError')}
              open={isErrorLoadingTables === true ? undefined : false}
            >
              <Button
                css={cssRefetchButton}
                title={t('sider.reloadBtn')}
                icon={<Refetch color="darkGrey" />}
                danger={isErrorLoadingTables}
                size="small"
                onClick={reloadTables}
              />
            </Tooltip>
          )}
        </Space>
      </Space>
      <Space full css={cssSearchContainer}>
        <Input
          css={cssSearchField}
          prefix={<SearchOutlined />}
          placeholder={t('sider.search')}
          onChange={onSearchHandled}
          allowClear
        />
      </Space>
    </Space>
  );
};

interface TableListSiderToolbarProps {
  isLoadingData: boolean;
  reloadTables: () => void;
  isErrorLoadingTables: boolean;
  setSearch: (searchText: string) => void;
}

const cssSpinner = css({
  paddingLeft: '0.25rem',
});

const cssContainer = (theme: Theme): CSSObject => ({
  padding: '12px 16px',
  gap: '10px',
  borderBottom: `1px solid ${theme['color-grey-400']}`,
});

const cssInnerContainer = (): CSSObject => ({
  height: '32px',
  minWidth: '184px',
});

const cssSearchContainer = (): CSSObject => ({
  ...cssInnerContainer(),
  '& .ant-space-item': {
    width: '100%',
  },
});

const cssSearchField = (theme: Theme): CSSObject => ({
  '& .ant-input-prefix': {
    color: theme['color-grey-500'],

    '.anticon-search': {
      height: '24px',
      width: '24px',

      '> svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
});

const cssTitle = (): CSSObject => ({
  fontSize: '18px',
  lineHeight: '27px',

  '&.ant-typography': {
    marginBottom: 0,
  },
});

const cssRefetchButton = (): CSSObject => ({
  background: 'transparent',
  border: 'none',

  '&&': {
    width: '32px',
    height: '32px',
  },
});

const cssEnvTag = (): CSSObject => ({
  marginInlineEnd: 0,
});
