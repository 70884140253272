import { appApi } from '@config/appApi';
import { ANALYSIS_PACKAGE_TAG_DESCRIPTION } from '@modules/library/analysisPackage/duck/analysisPackageConstants';
import {
  DeploymentStatus,
  IAnalysisDeploymentQueryParams,
  IAnalysisPackage,
  IAnalysisPackageDeploymentListResponse,
  IAnalysisPackageNewVersionResponse,
  IAnalysisPackageQueryParams,
  IAnalysisPackageResponse,
  TAnalysisPackageDeploymentFiltersResponse,
} from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { ILibraryCDRResponse } from '@modules/library/cdr/LibraryCdrTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const AnalysisPackageApiRoutes = {
  list: '/api/packages',
  analysisPackage: (id: number) => `/api/packages/${id}`,
  deployAnalysisPackage: (packageID: number) => `/api/packages/${packageID}/deployments`,
  deploymentsList: '/api/packages/deployments',
  deploymentsFilters: '/api/packages/deployments/filters',
  newPackageVersion: '/api/packages/new-version',
  deploymentStatuses: 'api/packages/deployments/status',
};

const AnalysisPackageInvalidations: {
  LIST: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST>;
  LIST_CDR_AP: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_CDR_AP>;
  LIST_ENVS: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_ENVS>;
  ID: (id: number) => TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.ID>;
  LIST_DEPLOYMENT: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_DEPLOYMENT>;
  LIST_DEPLOYMENT_FILTERS: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_DEPLOYMENT_FILTERS>;
  DEPLOYMENT_STATUSES: TagDescription<ANALYSIS_PACKAGE_TAG_DESCRIPTION.DEPLOYMENT_STATUSES>;
} = {
  LIST: { type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST, id: 'LIST' },
  ID: (id: number) => ({ type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.ID, id }),
  LIST_CDR_AP: { type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_CDR_AP, id: 'LIST_CDR_AP' },
  LIST_ENVS: { type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_ENVS, id: 'LIST_ENVS' },
  LIST_DEPLOYMENT: { type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_DEPLOYMENT, id: 'LIST_DEPLOYMENT' },
  LIST_DEPLOYMENT_FILTERS: {
    type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.LIST_DEPLOYMENT_FILTERS,
    id: 'LIST_DEPLOYMENT_FILTERS',
  },
  DEPLOYMENT_STATUSES: { type: ANALYSIS_PACKAGE_TAG_DESCRIPTION.DEPLOYMENT_STATUSES, id: 'DEPLOYMENT_STATUSES' },
};

export const AnalysisPackageApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisPackageList: builder.query<IAnalysisPackageResponse, IAnalysisPackageQueryParams | void>({
      providesTags: [AnalysisPackageInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisPackageApiRoutes.list,
      }),
    }),
    analysisPackageById: builder.query<IAnalysisPackage, number>({
      providesTags: (request, error, id) => [AnalysisPackageInvalidations.ID(id)],
      query: (id) => ({
        url: AnalysisPackageApiRoutes.analysisPackage(id),
      }),
    }),
    newPackageVersion: builder.query<IAnalysisPackageNewVersionResponse, void>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        params,
        url: AnalysisPackageApiRoutes.newPackageVersion,
      }),
    }),
    deploymentList: builder.query<IAnalysisPackageDeploymentListResponse, IAnalysisDeploymentQueryParams | void>({
      providesTags: [AnalysisPackageInvalidations.LIST_DEPLOYMENT, AnalysisPackageInvalidations.LIST],
      query: (params) => ({
        params,
        url: AnalysisPackageApiRoutes.deploymentsList,
      }),
    }),
    deploymentFilters: builder.query<TAnalysisPackageDeploymentFiltersResponse, void>({
      providesTags: [AnalysisPackageInvalidations.LIST_DEPLOYMENT_FILTERS],
      query: (params) => ({
        params,
        url: AnalysisPackageApiRoutes.deploymentsFilters,
      }),
    }),
    saveAnalysisPackage: builder.mutation<void, IAnalysisPackageSaveQuery>({
      invalidatesTags: (request, error, data) =>
        data.id
          ? [AnalysisPackageInvalidations.LIST, AnalysisPackageInvalidations.ID(data.id)]
          : [AnalysisPackageInvalidations.LIST],
      query: (data) => ({
        data,
        method: data.id ? 'PUT' : 'POST',
        url: AnalysisPackageApiRoutes.list,
      }),
    }),
    deleteAnalysisPackage: builder.mutation<void, number>({
      invalidatesTags: (request, error, id) => [AnalysisPackageInvalidations.LIST, AnalysisPackageInvalidations.ID(id)],
      query: (id) => ({
        method: 'DELETE',
        url: AnalysisPackageApiRoutes.analysisPackage(id),
      }),
    }),
    deployAnalysisPackage: builder.mutation<void, IAnalysisPackageDeployQuery>({
      invalidatesTags: [AnalysisPackageInvalidations.LIST_DEPLOYMENT, AnalysisPackageInvalidations.LIST],
      query: ({ env, packageId, studyName }) => ({
        data: {
          study_name: studyName,
          env,
        },
        method: 'POST',
        url: AnalysisPackageApiRoutes.deployAnalysisPackage(packageId),
      }),
    }),
    deploymentStatuses: builder.query<DeploymentStatus[], void>({
      providesTags: [AnalysisPackageInvalidations.DEPLOYMENT_STATUSES],
      query: (params) => ({
        params,
        url: AnalysisPackageApiRoutes.deploymentStatuses,
      }),
    }),
  }),
});

export const {
  useNewPackageVersionQuery,
  useAnalysisPackageListQuery,
  useAnalysisPackageByIdQuery,
  useDeploymentListQuery,
  useDeploymentFiltersQuery,
  useSaveAnalysisPackageMutation,
  useDeleteAnalysisPackageMutation,
  useDeployAnalysisPackageMutation,
  useLazyDeploymentStatusesQuery,
} = AnalysisPackageApi;

interface IAnalysisPackageSaveQuery {
  id?: number;
  version: string;
  description: string;
  objects: IAnalysisPackage['objects'];
}

interface IAnalysisPackageDeployQuery {
  packageId: number;
  studyName: string;
  env: string;
}

export interface IAnalysisPackageCdrListResponse extends ILibraryCDRResponse {}
