import { ViewerGroupType } from '@modules/viewer/ViewerTypes';
import { filteredTreeData } from '@shared/utils/Viewer';
import { ICrossStudyResolver } from '@routes/study/RoutesStudy';
import { useLocationResolver } from '@routes/routesHooks';
import { useCallback, useState } from 'react';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useCrossStoreAggregation, useTableListSider } from './tableListSiderHooks';
import { ITableListSiderProps } from './TableListSiderTypes';
import { TableListSiderTemplate, TableListSiderTreeItem } from './TableListSiderTemplate';

export const CrossTableListSider = ({
  selectedTable,
  defaultTableName,
  defaultFolderName,
  defaultFolderId,
  onSelectTable,
  refetch,
  draggable,
}: ITableListSiderProps) => {
  const { state: locationState } = useLocationResolver<
    ICrossStudyResolver['dataViewer']['params'],
    { external?: boolean }
  >();
  const isExternalRoute = locationState?.external;
  const { treeData, globalStudy, globalTablesQuery } = useCrossStoreAggregation(draggable);
  const { expandedGroups, selectedKeys, onSelect, onExpand, onDragStart } = useTableListSider({
    globalStudy,
    treeData,
    defaultTableName,
    defaultFolderName,
    defaultFolderId,
    selectedTable,
    onSelectTable,
  });
  const [searchText, setSearchText] = useState('');

  const filteredTree = searchText ? filteredTreeData(treeData, searchText) : treeData;

  const loadingMap: Partial<Record<ViewerGroupType, boolean>> = {
    crossStudyRT: globalTablesQuery.isFetching,
  };

  const isLoadingData = Object.values(loadingMap).some((el) => el);

  const customTitle = useCallback(
    (node: INodeRendererProps, draggable?: boolean) => (
      <TableListSiderTreeItem
        node={node}
        draggable={draggable}
        refetch={refetch}
        onSelectTable={onSelectTable}
        selectedTable={selectedTable}
        isExternalRoute={isExternalRoute}
      />
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalTablesQuery.isFetching, selectedTable, onSelectTable],
  );

  const reloadTables = () => {
    globalTablesQuery.refetch();
    if (selectedTable) {
      refetch();
    }
  };

  return (
    <TableListSiderTemplate
      treeData={filteredTree}
      selectedKeys={selectedKeys}
      expandedKeys={expandedGroups}
      draggable={draggable}
      selectedTable={selectedTable}
      titleRender={customTitle}
      onExpand={onExpand}
      onDragStart={onDragStart}
      onSelect={onSelect}
      refetch={refetch}
      isErrorLoadingTables={globalTablesQuery.isError}
      reloadTables={reloadTables}
      isLoadingData={isLoadingData}
      setSearch={setSearchText}
    />
  );
};
