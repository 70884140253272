import { Checkbox, Collapse, Modal, Typography } from '@ui';
import { ModalDivider } from '@components';
import { ReactNode, useState } from 'react';
import { CSSObject } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'antd';

export const InformationModal = ({
  title,
  userFriendlyErrorMessage,
  message,
  onConfirm,
  onClose,
  width,
  notShowAgainFlag,
  autoHeightMin = 200,
  footer,
  withActionDivider,
  ...props
}: IInformationModalProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const onCheckboxChange = (e: { target: { checked: boolean } }) => {
    localStorage.setItem('doNotShowInfoModal', e.target?.checked.toString());
    setChecked(e.target.checked);
  };

  const renderMessage = () => (
    <Scrollbars
      autoHide={false}
      autoHeight
      autoHeightMin={userFriendlyErrorMessage ? 300 : autoHeightMin}
      autoHeightMax={500}
    >
      {typeof message === 'string' ? <pre css={cssError}>{message}</pre> : message}
    </Scrollbars>
  );

  return (
    <Modal
      destroyOnClose
      onCancel={onClose}
      zIndex={1100}
      open
      title={
        <Typography.Title level={4} css={cssTitle}>
          {title ?? t('information')}
        </Typography.Title>
      }
      width={width || '800px'}
      footer={
        <>
          {withActionDivider && <ModalDivider />}
          {footer}
        </>
      }
      {...props}
      css={cssModal}
    >
      {userFriendlyErrorMessage ? (
        <>
          <Typography.Paragraph
            ellipsis={{ rows: 10, expandable: true, symbol: 'more' }}
            css={cssUserFriendlyErrorMessage}
          >
            {userFriendlyErrorMessage}
          </Typography.Paragraph>
          <Collapse css={cssCollapse}>
            <Collapse.Panel header="Details" key="1">
              {renderMessage()}
            </Collapse.Panel>
          </Collapse>
        </>
      ) : (
        renderMessage()
      )}

      {notShowAgainFlag && (
        <Checkbox onChange={onCheckboxChange} checked={checked}>
          {t('dontShowAgain')}
        </Checkbox>
      )}
    </Modal>
  );
};

const cssError = (): CSSObject => ({
  fontSize: '12px',
  overflow: 'visible',
  textWrap: 'wrap',
});

const cssTitle = (): CSSObject => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'start',
  width: '95%',
  padding: '4px 0',
  fontVariant: 'lining-nums',
});

const cssUserFriendlyErrorMessage = (): CSSObject => ({
  padding: '16px 0',
});

const cssCollapse = (): CSSObject => ({
  border: 'none',
  marginBottom: '1em',

  '& .ant-collapse-item': {
    border: 'none',
  },
  '& .ant-collapse-content': {
    border: 'none',
    background: 'transparent',
  },
});

const cssModal = (): CSSObject => ({
  '& .ant-modal-close': {
    top: 24,
    width: 40,
    height: 40,
  },
  '& .ant-modal-content': {
    paddingTop: '24px',
    paddingBottom: '12px',
  },
  '& .ant-modal-header': {
    marginBottom: '16px',
  },
});

interface IInformationModalProps extends Exclude<ModalProps, 'modalRender'> {
  message: ReactNode;
  userFriendlyErrorMessage?: string;
  title?: string;
  onConfirm?: (dontShowAgainChecked: boolean) => void;
  onClose: () => void;
  width?: string;
  notShowAgainFlag?: boolean;
  autoHeightMin?: number;
  withActionDivider?: boolean;
}
