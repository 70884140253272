import { configuration } from '@config/constants';
import { prepareCurrentEnvironment } from '@modules/viewer/duck/viewerUtils';
import { GlobalModalsState, GlobalPushModal } from '@app/modals';
import { authActions } from '@modules/auth/duck/AuthSlice';
import { IAppLibraryContext, IAppStudyContext, IEnvironment } from '@app/AppTypes';
import { DEFAULT_PANEL_WIDTH } from '@components/MainMenu/components/ResizablePanel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Key } from 'antd/es/table/interface';

interface AppStore {
  ready: boolean;
  isUnauthorized: boolean;
  isForbidden: boolean;
  innerSiderWidth: number;
  context: {
    library: null | IAppLibraryContext;
    study: null | IAppStudyContext;
  };
  environment: IEnvironment | null;
  mainMenuState: MainMenuState;
  modals: GlobalModalsState;
}

type SetContextPayload = { type: 'library' | 'study'; context: IAppLibraryContext | IAppStudyContext | null };

interface MainMenuState {
  width: number;
  expandedKeys: Key[];
}

const initialState: AppStore = {
  ready: false,
  isUnauthorized: false,
  isForbidden: false,
  innerSiderWidth: configuration.defaultInnerSiderWidth,
  context: {
    library: null,
    study: null,
  },
  environment: null,
  mainMenuState: {
    width: DEFAULT_PANEL_WIDTH,
    expandedKeys: [],
  },
  modals: { selectedModal: null },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setContext: (state, { payload }: PayloadAction<SetContextPayload>) => {
      state.context = {
        ...{ library: null, study: null },
        [payload.type]: payload.context,
      };
    },
    setUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    setForbidden: (state, action: PayloadAction<boolean>) => {
      state.isForbidden = action.payload;
    },
    setInnerSiderWidth: (state, action: PayloadAction<number>) => {
      state.innerSiderWidth = action.payload;
    },
    setENVSwitch: (state, action: PayloadAction<IEnvironment | null>) => {
      state.environment = action.payload;
    },
    setMainMenuState: (state, action: PayloadAction<Partial<MainMenuState>>) => {
      state.mainMenuState = { ...state.mainMenuState, ...action.payload };
    },

    pushModal: (state, action: PayloadAction<GlobalPushModal>) => {
      const { type, data } = action.payload;
      state.modals = { selectedModal: type, data };
    },
    dropModal: (state, action: PayloadAction<void>) => {
      state.modals = initialState.modals;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.setUser, (state, action) => {
      const userEnvs = action.payload?.environments || {};
      const userCurrentEnv = action.payload?.env_name || '';
      state.environment = prepareCurrentEnvironment(userCurrentEnv, userEnvs);
    });
  },
});

export const appActions = appSlice.actions;

export const appReducer = appSlice.reducer;
