export enum SELECTED_ACTIONS {
  'SHOW_ALL' = 'SHOW_ALL',
  'HIDE_SELECTED' = 'HIDE_SELECTED',
}

export const SEPARATOR_LOCALE = 'en-US';

export const PREFIX_CODE_LAB_DATASET = 'CL_';
export const PREFIX_SQL_LAB_DATASET = 'SL_';
export const PREFIX_REPORT_DATASET = 'CDR_';

export const NO_WRAP_SPACE = '\u00A0';

export const STATUSES_TIMEOUT = 5000;
