import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { useLibraryDeletePscMutation, useLibraryPscListQuery } from '@modules/library/psc/duck/libraryPscApi';
import { ILibraryPsc, TLibraryPscDeleteProps } from '@modules/library/psc/PscTypes';
import { LibraryPscList } from '@modules/library/psc/components/LibraryPscList';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ELibraryPscModalsType } from '@modules/library/psc/modals';
import { libraryPscActions } from '@modules/library/psc/duck/libraryPscSlice';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibraryPscRootPage = () => {
  const dispatch = useDispatch();
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const { t } = useTranslation(['libraryPsc']);
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibraryPsc>(initialPage);

  const pscReportQuery = useLibraryPscListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deletePsc, deletePscQuery] = useLibraryDeletePscMutation();

  const {
    appPermissions: { canGlPscInsert, canGlLibraryUpdate, canGlPscDelete },
  } = useAppPermissions();

  const pagination = getPagination(pscReportQuery.data?.totalItems);

  const copyPsc = () => {
    dispatch(libraryPscActions.pushModal({ type: ELibraryPscModalsType.copyPsc }));
  };

  const onDelete = async (value: TLibraryPscDeleteProps) => {
    const { id, name } = value;
    try {
      await deletePsc(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('confirmation.errorMessage', { name }) });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        level: 2,
        children: t('pageRootName'),
        refetch: pscReportQuery.refetch,
        extra: libraryIsEditable && canGlPscInsert && <Button children={t('import')} onClick={copyPsc} />,
      }}
      content={{
        isLoading: pscReportQuery.isLoading && !pscReportQuery.data,
        errorText: t('loadingError'),
        error: pscReportQuery.error,
      }}
    >
      {!pscReportQuery.isLoading && (
        <LibraryPscList
          data={pscReportQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlPscDelete ? onDelete : undefined}
          pagination={pagination}
          loading={pscReportQuery.isFetching}
          loadingDelete={deletePscQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
