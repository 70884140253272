import { RootState } from '@store';

export const selectAppReady = (state: RootState) => state.app.ready;

export const selectAppIsUnauthorized = (state: RootState) => state.app.isUnauthorized;

export const selectAppIsForbidden = (state: RootState) => state.app.isForbidden;

export const selectAppInnerSiderWidth = (state: RootState) => state.app.innerSiderWidth;

export const selectGlobalStudy = (state: RootState) => state.app.context.study;

export const selectGlobalLibrary = (state: RootState) => state.app.context.library;

export const selectStudyContextParams = (state: RootState) => ({
  studyId: state.app.context.study?.id!,
});

export const selectLibraryContextParams = (state: RootState) => ({
  libraryId: state.app.context.library?.id!,
});

export const selectAppENV = (state: RootState) => state.app?.environment;

export const selectAppliedENVSwitch = (state: RootState) =>
  state.app?.environment?.current ? undefined : state.app?.environment?.env;

export const selectMainMenuState = (state: RootState) => state.app?.mainMenuState;

export const selectModal = (state: RootState) => state.app.modals;
