import { ELibraryModalsType, LibraryModalsController } from '@modules/library/root/modals';
import { Button } from '@ui';
import { initialPage } from '@shared/utils/common';
import { libraryActions } from '@modules/library/root/duck/librarySlice';
import { PageTemplateSimple } from '@components';
import { Library } from '@modules/library/root/LibraryTypes';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLibraryListQuery } from '../duck/libraryApi';
import { LibraryList } from '../components';

export const LibraryRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryRoot']);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<Library>(initialPage);

  const {
    appPermissions: { canGlLibraryInsert },
  } = useAppPermissions();

  const libraryListQuery = useLibraryListQuery({
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const pagination = getPagination(libraryListQuery.data?.totalItems);

  const saveLibrary = () => dispatch(libraryActions.pushModal({ type: ELibraryModalsType.saveLibrary }));

  return (
    <>
      <PageTemplateSimple
        hideTitleSkeleton
        title={{
          level: 2,
          children: t('root.title'),
          refetch: libraryListQuery.refetch,
          extra: canGlLibraryInsert && <Button children={t('add')} onClick={saveLibrary} />,
        }}
        content={{
          errorText: t('root.loadingError'),
          error: !libraryListQuery.data ? libraryListQuery.error : undefined,
          isLoading: libraryListQuery.isLoading && !libraryListQuery.data,
        }}
      >
        {libraryListQuery.data && (
          <LibraryList data={libraryListQuery.data} onChange={onTableChange} pagination={pagination} />
        )}
      </PageTemplateSimple>
      <LibraryModalsController />
    </>
  );
};
