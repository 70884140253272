import { I18nLocales } from '@i18n';
import en from './appHeader_en.json';

export const appHeaderLocales: Record<I18nLocales, I18nAppHeader> = {
  en,
};

interface I18nAppHeader {
  env: {
    confirmationTitle: string;
    confirmationText: string;
    switchInstance: string;
  };
}
