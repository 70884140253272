import { DATE_FORMAT } from '@modules/job/modals/components/upload/duck/uploadConstants';
import { Button } from '@ui';
import { FormChangeValues } from '@modules/job/modals/components/upload/steps/components/dateTimePopup/RequiredDateTimeFieldsPopup';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

export const ApplyButton = ({
  isConvertDate,
  tableName,
  index,
  handlePopoverClose,
  handlesColumnChangeIndex,
  t,
}: ApplyButtonProps) => {
  const form = useFormInstance();

  const handleButtonClick = async () => {
    try {
      let values = {};
      const fieldName = isConvertDate
        ? [tableName, 'mapping', index, 'format']
        : [tableName, 'mapping', index, 'valueDatePicker'];

      await form.validateFields([fieldName]);

      if (isConvertDate) {
        const convertFormat = form.getFieldValue(fieldName);
        values = {
          value: 'DateTime64',
          convert: true,
          format: convertFormat,
          customTypeValue: t('uploadRT.convert', { format: convertFormat }),
        };
      } else {
        const dateValue = form.getFieldValue(fieldName);
        values = {
          value: dateValue.toISOString(),
          convert: false,
          customTypeValue: `${t('uploadRT.override')}: ${dateValue.format(DATE_FORMAT)}`,
        };
      }

      handlesColumnChangeIndex?.(values, index);

      handlePopoverClose();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Button css={cssButton} type="primary" onClick={handleButtonClick}>
      {t('uploadRT.apply')}
    </Button>
  );
};

const cssButton = (): CSSObject => ({
  marginTop: 8,
});

interface ApplyButtonProps {
  isConvertDate: boolean;
  tableName: string;
  index: number;
  handlesColumnChangeIndex?: (values: FormChangeValues, index: number) => void;
  handlePopoverClose: () => void;
  t: TFunction;
}
