export const getAppConfig = () => ({
  version: '1.0.5',
});

export const configuration = {
  loginURL: '/login-sso',
  logoutURL: '/logout',
  apiUrl: '/',
  defaultInnerSiderWidth: 224,
};

export const CrossStudyId = 0;

export const MandatoryColumns = ['CDR_SKEY', 'MODIFIEDDATETIME'];
export const MandatoryColumnsWithAutogeneration = ['CDR_SKEY'];

// TODO in future change to the object like { MODIFIEDDATETIME: 'DateTime64', ORDER: 'Int32'...}. To do more universal (https://redcapcloud.atlassian.net/browse/DNA-4677)
export const MandatoryUploadDateTimeColumns = ['MODIFIEDDATETIME'];

export const DataStageTablePrefix = 'DS_';

export const GPDIP_FEATURE_NAME = 'gpdip';
export const DEPLOYMENT_FEATURE_NAME = 'deployment';
export const GL_FEATURE_NAME = 'gl';

export const RCC_EXPORT_OBJ_TYPE_TO_NAME_MAP: Record<string, string> = {
  discrepancy_title: 'Queries',
};

export const SIDEBAR_MENU_WIDTH = 96;
export const SIDEBAR_MENU_LOGO_CONTAINER_HEIGHT = 90;

// limit for data store name, data source name, global library name
export const NAME_LIMIT = 128;
// limit for data model name, dataset name, column name
export const NAME_LIMIT_BIG = 168;
