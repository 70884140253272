export const emptyError = {
  isError: false,
  errorMessage: '',
};

export const initialMapping = {
  referenceTable: undefined,
  total_columns: 0,
  total_rows: 0,
  mapping: [],
  confirmed: false,
  skip: false,
  ...emptyError,
};

export const DEFAULT_PK = 'CDR_SKEY';

export const DATE_FORMAT = 'MMM D, YYYY h:mm:ss A';
export const TIME_FORMAT = 'h:mm:ss A';

export const DATETIME_FORMATS = [
  'DD/MM/YYYY HH:mm:ss',
  'DD/MM/YYYY h:mm:ss A',
  'DD-MMM-YYYY HH:mm:ss',
  'DD-MMM-YYYY h:mm:ss A',
  'MM/DD/YY h:mm:ss A',
  'MM/DD/YYYY h:mm:ss A',
  'MM-DD-YY h:mm:ss A',
  'MM-DD-YYYY h:mm:ss A',
  'MM/DD/YY HH:mm:ss',
  'MM/DD/YYYY HH:mm:ss',
  'MM-DD-YY HH:mm:ss',
  'MM-DD-YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY-MM-DD h:mm:ss A',
  'YYYY/MM/DD h:mm:ss A',
  'YYYY-MM-DDTHH:mm:ssZ',
];
