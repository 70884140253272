import { DraggableModal, notification } from '@ui';
import { CodeLabObjectSelectForm, ICodeLabObjectSelectFormProps } from '@modules/dnaObject/CodeLab/components';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { ICodeLabObject } from '@modules/dnaObject/CodeLab/CodeLabObjectTypes';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { prepareCodeLabObjectForImport } from '@modules/dnaObject/CodeLab/duck/codeLabUtils';
import { ICascadeObjectsTableData, LibraryCascadeObjects } from '@shared/components/CascadeObjects';
import { ICodeLab } from '@modules/codeLab/CodeLabTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { isObject } from 'lodash';
import {
  useLibraryObjectImportCodeLabMutation,
  useLibraryObjectValidateCodeLabMutation,
} from '../duck/libraryObjectApi';

const LibraryObjectModalImportCodeLabContent = ({ onClose, t }: ILibraryObjectModalImportCodeLabContentProps) => {
  const [importLibraryCodeLab] = useLibraryObjectImportCodeLabMutation();
  const [validateCodeLab] = useLibraryObjectValidateCodeLabMutation();
  const confirmModal = useConfirmModal();
  const [isCascade, setIsCascade] = useState(false);
  const [selectedCascadeObjects, setSelectedCascadeObjects] = useState<ICascadeObjectsTableData[]>([]);

  const onImport: ICodeLabObjectSelectFormProps['onSubmit'] = async (
    values: ICodeLab[] | ICodeLabObject[],
    { systemInfo, overwrite, kind },
  ) => {
    if (overwrite) {
      try {
        await validateCodeLab({ python_notebook_names: values.map((item) => item.name) }).unwrap();

        await importLibraryCodeLab({
          data: prepareCodeLabObjectForImport(values, kind, systemInfo!),
          overwrite: overwrite,
        }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;

        // TODO Revert after https://redcapcloud.atlassian.net/browse/DNA-4625
        const cascadingError = isObject(data.rawData?.error) ? data.rawData?.error : {};
        confirmModal.openConfirmation({
          content: (
            <div style={{ width: '100%' }}>
              {t('confirmOverwrite.content')}
              <strong>{Object.keys(cascadingError).join(', ')}</strong>
            </div>
          ),
          data: prepareCodeLabObjectForImport(values, kind, systemInfo!),
          cascadeObjects: cascadingError,
        });

        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await importLibraryCodeLab({
        data: prepareCodeLabObjectForImport(values, kind, systemInfo!),
        overwrite,
      }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      const processSelectedCascadeObjects = selectedCascadeObjects.filter((el) => !el.children);
      await importLibraryCodeLab({ data, cascade_update: processSelectedCascadeObjects, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  const clearCascadeData = () => {
    setIsCascade(false);
    setSelectedCascadeObjects([]);
  };

  return (
    <>
      <CodeLabObjectSelectForm
        onClose={onClose}
        onSubmit={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
      />
      <ConfirmModal
        title={t('confirmOverwrite.content')}
        submitFunc={onOverwriteConfirm}
        {...confirmModal}
        closeConfirmation={() => {
          clearCascadeData();
          confirmModal.closeConfirmation();
        }}
      >
        <LibraryCascadeObjects
          data={confirmModal.confirmState?.cascadeObjects}
          isCascade={isCascade}
          setIsCascade={setIsCascade}
          setSelectedCascadeObjects={setSelectedCascadeObjects}
        />
      </ConfirmModal>
    </>
  );
};

export const LibraryObjectModalImportCodeLab = ({ open, data, onClose }: ILibraryObjectModalImportCodeLabProps) => {
  const { t } = useTranslation(['libraryObject']);

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('codeLabObject.importForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <LibraryObjectModalImportCodeLabContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryObjectModalImportCodeLabProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface ILibraryObjectModalImportCodeLabContentProps
  extends Pick<ILibraryObjectModalImportCodeLabProps, 'data' | 'onClose'> {
  t: TFunction;
}
