import { handleQueryError, QueryErrorType } from '@shared/utils/Error';
import { configuration } from '@config/constants';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

const requestTimeout = 60000;

const createAppAxiosInstance = () =>
  axios.create({
    baseURL: configuration.apiUrl,
    timeout: requestTimeout,
  });

const appAxios = createAppAxiosInstance();

appAxios.interceptors.response.use(undefined, (err) => {
  const { config } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      console.log('retry the request', config.url);
      resolve(true);
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => appAxios(config));
});

appAxios.interceptors.request.use(
  (config) => {
    config.headers['x-role-id'] = sessionStorage.getItem('role_id');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function setAppAxiosConfigs(context: { library_id?: number; study_id?: number }) {
  appAxios.defaults.params = context;
}

function getAppApiUrl(url: string) {
  return `${configuration.apiUrl}${url.replace(/^\//, '')}`;
}

export type AppBaseQueryFn = BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    formData?: FormData;
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
    timeout?: AxiosRequestConfig['timeout'];
  },
  unknown,
  QueryErrorType,
  {}
>;

export const appAxiosBaseQuery =
  (): AppBaseQueryFn =>
  async ({ url, method, data, params, headers, formData, timeout }, { signal }) => {
    try {
      if (formData) {
        const result = await appAxios.post(getAppApiUrl(url), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return { data: result.data };
      }

      const result = await appAxios({
        timeout: timeout ?? requestTimeout,
        url: getAppApiUrl(url),
        method,
        data,
        params,
        headers,
        signal,
      });

      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          ...handleQueryError(err, err.message),
        },
      };
    }
  };

export { appAxios, setAppAxiosConfigs, getAppApiUrl };
