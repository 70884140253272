import { selectGlobalStudy } from '@app/duck/appSelectors';
import { Beaker, StudySwitcherIcon } from '@components/icons';
import RoutesStudy from '@routes/study/RoutesStudy';
import { getMatchedResolver } from '@shared/utils/RoutesUtils';
import routes from '@routes';
import { MenuSelector } from '@components/MainMenu/components/MenuSelector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TreeDataNode, TreeProps } from 'antd';
import sortBy from 'lodash/sortBy';
import { StudyResponse } from '../StudyTypes';
import { useStudyListQuery } from '../duck/studyApi';

export const StudySelector = () => {
  const globalStudy = useSelector(selectGlobalStudy);

  const studiesQuery = useStudyListQuery();

  const navigate = useNavigate();

  const { t } = useTranslation(['study']);

  const studies = sortBy(studiesQuery.data, 'name');

  const { isLoading } = studiesQuery;

  const studiesTree: StudyDataNode[] = useMemo(
    () =>
      isLoading
        ? []
        : studies.map((study) => ({
            title: study.name,
            key: study.id,
            isLeaf: true,
            rawData: study,
            icon: <Beaker />,
          })),
    [studies, isLoading],
  );

  const handleSelectNode: TreeProps<StudyDataNode>['onSelect'] = (_, { node }) => {
    const { isLeaf, rawData } = node;

    const currentPath = window.location.pathname;
    const resolver = getMatchedResolver(currentPath, RoutesStudy);

    if (isLeaf && rawData) {
      if (resolver) {
        navigate(resolver({ studyId: rawData.id }), {
          state: {
            protocolId: rawData.protocol,
            name: rawData.name,
            studySchema: rawData.study_schema,
            configured: rawData.configured,
          },
        });
      } else {
        navigate(routes.study.view.resolver({ studyId: rawData.id }), {
          state: {
            protocolId: rawData.protocol,
            name: rawData.name,
            studySchema: rawData.study_schema,
            configured: rawData.configured,
          },
        });
      }
    }
  };

  return (
    <MenuSelector
      treeData={studiesTree}
      selectedKeys={globalStudy ? [globalStudy.id] : []}
      onSelect={handleSelectNode}
      title={globalStudy?.name ?? ''}
      category="Study "
      noDataText={t('selector.noStudies')}
      isLoading={isLoading}
      Icon={StudySwitcherIcon}
    />
  );
};

interface StudyDataNode extends TreeDataNode {
  rawData?: StudyResponse;
}
