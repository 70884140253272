import { useDispatch, useSelector } from 'react-redux';
import {
  CodeLabModalsLog,
  CodeLabModalsLogProps,
  CodeLabModalsScheduler,
  ICodeLabModalsSchedulerProps,
} from './components';
import { selectCodeLabModals } from '../duck/codeLabSelectors';
import { codeLabActions } from '../duck/codeLabSlice';
import { CodeLabModalsType } from '.';

export const CodeLabModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectCodeLabModals);

  const closeModal = () => {
    dispatch(codeLabActions.dropModal());
  };

  return (
    <>
      {data && (
        <CodeLabModalsLog
          open={selectedModal === CodeLabModalsType.logs}
          data={data as CodeLabModalsLogProps['data']}
          onClose={closeModal}
        />
      )}
      <CodeLabModalsScheduler
        open={selectedModal === CodeLabModalsType.scheduler}
        data={data as ICodeLabModalsSchedulerProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
