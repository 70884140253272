import { Loader, QueryError } from '@components';
import { useLibraryAppListener } from '@modules/library/root/duck/libraryHooks';
import { selectLibraryAppPreloaded } from '@modules/library/root/duck/librarySelectors';
import { LibraryMenu } from '@modules/library/root/components/LibraryMenu';
import { AppToolbarPortal } from '@app/components/Header/AppToolbarPortal';
import { AppGatewaySection } from '@app/components';
import { LibraryStatusTag } from '@modules/library/root/components';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { APP_HEADER_LEFT_ID } from '@app/components/Header/AppHeaderConstants';
import { Outlet } from 'react-router-dom';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';

export const LibraryAppRouterProvider = () => {
  const { queryError } = useLibraryAppListener();

  const libraryAppPreloaded = useSelector(selectLibraryAppPreloaded);
  const globalLibrary = useSelector(selectGlobalLibrary);

  if (queryError) {
    return <QueryError error={{ status: 404, data: { userMsg: 'Library Not Found' } }} title="Library Not Found" />;
  }

  return (
    <div css={cssBody}>
      <AppToolbarPortal id={APP_HEADER_LEFT_ID}>
        <AppGatewaySection onlyGLMode>
          <LibraryStatusTag status={globalLibrary?.status!} calm withMenu />
        </AppGatewaySection>
      </AppToolbarPortal>
      {<LibraryMenu />}
      <div css={cssContent}>{!libraryAppPreloaded ? <Loader mode="fixed" size="large" /> : <Outlet />}</div>
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'auto',
});
