import { useGpdipRunMutation } from '@modules/gpdip/duck/gpdipApi';
import { QueryErrorType } from '@shared/utils/Error';
import { Badge, Button, notification, Skeleton } from '@ui';
import { FINISHED_STATUSES } from '@modules/gpdip/duck/gpdipConstants';
import { GPDIP_STATUS, IGpdip } from '@modules/gpdip/GpdipTypes';
import { RunIcon } from '@components/icons';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

export const ActionButtons = ({ record, status, loading, t }: ActionButtonsProps) => {
  const [runQuery, runQueryData] = useGpdipRunMutation();

  const runExport = async (record: IGpdip) => {
    const { type, id, name } = record;
    try {
      await runQuery({ type, id }).unwrap();
      notification.success({
        message: t('rootTable.successRun', { name }),
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: t('errors.failedRun', { name }),
        description: (err as QueryErrorType).data.userMsg,
        duration: 0,
      });
    }
  };

  const renderStatus = () => {
    switch (status) {
      case GPDIP_STATUS.PENDING:
        return <Badge status="default" text={t('rootTable.status.pending')} />;
      case GPDIP_STATUS.STARTED:
        return <Badge status="processing" text={t('rootTable.status.started')} />;
      case GPDIP_STATUS.FAILED:
        return <Badge status="error" text={t('rootTable.status.failed')} />;
      case GPDIP_STATUS.FINISHED:
        return <Badge status="success" text={t('rootTable.status.finished')} />;
    }
  };

  if (runQueryData.isLoading || loading) {
    return <Skeleton.Button active />;
  }

  if (!status || FINISHED_STATUSES.includes(status)) {
    return <Button icon={<RunIcon />} onClick={() => runExport(record)} />;
  }

  return <div css={cssContainer}>{renderStatus()}</div>;
};

const cssContainer = (): CSSObject => ({
  lineHeight: '32px',
});

interface ActionButtonsProps {
  record: IGpdip;
  status?: GPDIP_STATUS;
  t: TFunction;
  loading: boolean;
}
