import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button } from '@ui';
import { initialPage, isCrossStudy } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { ISQLLabObject } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { SQLLabObjectList } from '@modules/dnaObject/SQLLab/components/SQLLabObjectList';
import { useAppContext } from '@app/AppContext';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import {
  useAnalysisObjectSQLDatasetListQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
} from '../duck/analysisObjectApi';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

const wrapMixPermissions = (Component: ComponentType<IAnalysisObjectSQLLabRootPageProps>) => {
  const WrapperComponent = () => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      userPermissions: { canGlSqlAoImport, canGlSqlAoDelete },
    } = useStudyPermissions();
    const {
      appPermissions: { canCrossSqlAoImport, canCrossSqlAoDelete },
    } = useAppPermissions();
    const crossStudy = isCrossStudy(globalStudy?.id);

    return (
      <Component
        mixCanSqlAoImport={crossStudy ? canCrossSqlAoImport : canGlSqlAoImport}
        mixCanSqlAoDelete={crossStudy ? canCrossSqlAoDelete : canGlSqlAoDelete}
      />
    );
  };

  return WrapperComponent;
};

export const AnalysisObjectSQLLabRootPage = wrapMixPermissions(
  ({ mixCanSqlAoDelete, mixCanSqlAoImport }: IAnalysisObjectSQLLabRootPageProps) => {
    const { notificationApi } = useAppContext();
    const dispatch = useDispatch();
    const { t } = useTranslation(['analysisObjects']);
    const supportedEnvsQuery = useSupportedEnvsQuery();
    const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
      useTablePaginationState<ISQLLabObject>(initialPage);

    const sqlDatasetQuery = useAnalysisObjectSQLDatasetListQuery({
      detailed: '0',
      page: paginationState.current - 1,
      page_size: paginationState.pageSize,
      ...preparedFilters,
      ...preparedSorter,
    });

    const [deleteSQLDataset, deleteSQLDatasetQuery] = useDeleteAnalysisObjectSQLDatasetMutation();

    const pagination = getPagination(sqlDatasetQuery.data?.totalItems);

    const importSQLLabAOModal = () =>
      dispatch(analysisObjectActions.pushModal({ type: EAnalysisObjectModalsType.importSQLDataset }));

    const onDeleteSQLDataset = async (value: { id: number; version_id: number; name: string }) => {
      const { version_id, name } = value;
      try {
        await deleteSQLDataset(version_id).unwrap();
        notificationApi.success({ message: t('sqlLab.modals.deleteSuccess', { name }) });
      } catch (e) {
        notificationApi.error({ message: (e as QueryErrorType).data.userMsg });
      }
    };

    return (
      <PageTemplateSimple
        content={{
          isLoading: sqlDatasetQuery.isLoading && !sqlDatasetQuery.data,
          errorText: t('sqlLab.loadingListError'),
          error: sqlDatasetQuery.error,
        }}
        title={{
          children: t('sqlLab.rootPageName'),
          pageTitle: t('sqlLab.pageTitle'),
          refetch: sqlDatasetQuery.refetch,
          extra: mixCanSqlAoImport && <Button children={t('import')} onClick={importSQLLabAOModal} />,
        }}
      >
        {sqlDatasetQuery.data && (
          <SQLLabObjectList
            data={sqlDatasetQuery.data}
            onChange={onTableChange}
            pagination={pagination}
            loading={sqlDatasetQuery.isFetching}
            onDelete={mixCanSqlAoDelete ? onDeleteSQLDataset : undefined}
            loadingDelete={deleteSQLDatasetQuery.isLoading}
            supportedEnvs={supportedEnvsQuery.data || {}}
            t={t}
          />
        )}
      </PageTemplateSimple>
    );
  },
);

interface IAnalysisObjectSQLLabRootPageProps {
  mixCanSqlAoImport?: boolean;
  mixCanSqlAoDelete?: boolean;
}
