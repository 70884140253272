import { Library } from '@modules/library/root/LibraryTypes';
import { useSaveLibraryMutation } from '@modules/library/root/duck/libraryApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { DraggableModal, Form, FormItem, FormLayout, Input, notification, Alert } from '@ui';
import { NAME_LIMIT } from '@config/constants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Space } from 'antd';

const LibraryModalSaveLibraryContent = ({ data, onClose, t }: ILibraryModalsSaveLibraryContentProps) => {
  const [form] = Form.useForm();
  const [saveLibrary, { isError, error }] = useSaveLibraryMutation();

  const errorMessage = (isError && error && 'data' in error && error.data.userMsg) || null;

  const onSubmit = async (values: ILibrarySaveFieldFormValues) => {
    try {
      await saveLibrary({ ...values, status: LibraryStatus.Development }).unwrap();
      notification.success({
        message: t('saveForm.successMessageSave', { name: values.name }),
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormLayout form={form} onCancel={onClose} onSubmit={onSubmit} okText={t('save')}>
      <FormItem
        name="name"
        label={t('saveForm.name')}
        rules={[
          { required: true },
          {
            validator: (_, value) => {
              return value.length <= NAME_LIMIT
                ? Promise.resolve()
                : Promise.reject(new Error(t('errors.maxLength', { length: NAME_LIMIT })));
            },
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem name="description" label={t('saveForm.description')} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      {errorMessage && (
        <FormItem wrapperCol={{ span: 24 }}>
          <Alert message={<Space direction="vertical" children={errorMessage} />} type="error" />
        </FormItem>
      )}
    </FormLayout>
  );
};

export const LibraryModalSaveLibrary = ({ open, data, onClose }: ILibraryModalsSaveLibraryProps) => {
  const { t } = useTranslation(['libraryRoot']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('saveForm.title')} footer={null} destroyOnClose>
      {open && <LibraryModalSaveLibraryContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryModalsSaveLibraryProps {
  open: boolean;
  data: Partial<Library>;
  onClose: () => void;
}

interface ILibraryModalsSaveLibraryContentProps extends Pick<ILibraryModalsSaveLibraryProps, 'data' | 'onClose'> {
  t: TFunction;
}

interface ILibrarySaveFieldFormValues {
  name: string;
  description: string;
}
