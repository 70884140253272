import { reverse } from 'named-urls';

export const DATASTAGE_PREFIX = 'datastage-';
export const CDR_PREFIX = 'reports-';
export const RCC_PREFIX = 'cdms.';

export const getFormattedDnaPlatformUrl = (customParameters?: Record<string, string>) => {
  const { origin } = window.location;
  const isLocalhost = origin.includes('localhost');
  const customPath = customParameters?.customPath ?? '';
  const customParams = customParameters?.customParams ?? '';
  let generatedDnaPath;

  if (isLocalhost) {
    generatedDnaPath = 'https://reports-rcc-devuat.redcapcloud.com';
  } else {
    generatedDnaPath = origin.includes(DATASTAGE_PREFIX)
      ? origin.replace(DATASTAGE_PREFIX, CDR_PREFIX)
      : `${CDR_PREFIX}${origin}`;
  }

  const sessionReferrer = localStorage.getItem('referrer');
  const userName = localStorage.getItem('user_name');

  const params = [sessionReferrer && `referrer=${sessionReferrer}`, userName && `username=${userName}`]
    .filter(Boolean)
    .join('&');

  const fullParams = `${params}&${customParams}`;

  return `${generatedDnaPath}${customPath ?? ''}${fullParams ? `?${fullParams}` : ''}`;
};

export const getRCCDomain = () => {
  const { origin } = window.location;
  const isLocalhost = origin.includes('localhost');

  return isLocalhost ? 'https://cdms.rcc-devuat.redcapcloud.com' : origin.replace(DATASTAGE_PREFIX, RCC_PREFIX);
};

export const isValidStudyId = (id?: string | number) => {
  if (typeof id === 'string') {
    return Number.isNaN(Number(id)) === false;
  }
  if (typeof id === 'number') {
    return true;
  }
  return false;
};

export const getStudyPathName = (studyId: number) => (studyId === 0 ? 'crossStudy' : 'study');

export const getPathWithParams = (
  routePath: string,
  routeArgs: Record<string, number | string>,
  params?: Record<string, number | string> | void,
) => {
  const url = reverse(routePath, routeArgs);
  const urlParams = Object.entries(params || {})
    .filter(([key, value]) => value !== undefined && !Object.keys(routeArgs).includes(key))
    .map(([key, value]) => `${key}=${value}`);

  if (urlParams.length) {
    return `${url}?${urlParams.join('&')}`;
  }
  return url;
};
